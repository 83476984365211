import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { contactRoute, homeRoute, privacyPolicyRoute, reportRoute } from './routes';
import { LoadingLoader } from '../Loader/LopadingLoader';
import Home from '../../pages/Home/Home';
import PrivacyPolicy from '../../pages/PrivacyPolicy/PrivacyPolicy';
import Report from '../../pages/Report/Report';
import Contact from '../../pages/Contact/Contact';

// const Home = React.lazy(() => import('../../pages/Home/Home'));
// const PrivacyPolicy = React.lazy(() => import('../../pages/PrivacyPolicy/PrivacyPolicy'));
// const Report = React.lazy(() => import('../../pages/Report/Report'));
// const Contact = React.lazy(() => import('../../pages/Contact/Contact'));

const Browser = () => {
  // return <Home />;

  return (
    <Suspense fallback={<LoadingLoader />}>
      <Routes>
        <Route path={contactRoute()} element={<Contact />} />
        <Route path={homeRoute()} element={<Home />} />
        <Route path={privacyPolicyRoute()} element={<PrivacyPolicy />} />
        <Route path={reportRoute()} element={<Report />} />
      </Routes>
    </Suspense>
  );
};

export default Browser;
