import axios from 'axios';

export type Report = {
  index: number;
  description: string;
  id: string;
  help: string;
  wcag: string;
  tags: string[];
  impact: string;
  nodes: number;
};

export type Result = {
  success: boolean;
  message: string;
  url: string;
  data: Report[];
};

export type ReportResponse = {
  url: string;
  results: Result[];
};

type GetReportParams = {
  urls: string[];
  email: string;
  name?: string;
  recaptchaValue: string;
};

export type SendContactParams = {
  email: string;
  name: string;
  company?: string;
  website?: string;
  note?: string;
  recaptchaValue: string;
};

export const getReport = async ({ urls, email, name, recaptchaValue }: GetReportParams): Promise<ReportResponse> => {
  const response = await axios.post<ReportResponse>(`${process.env.REACT_APP_API_URL}/api/reports`, {
    urls,
    contact: {
      email,
      name
    },
    'g-recaptcha-response': recaptchaValue
  });

  if (response.data.results.some((r) => !r.success)) {
    if (response.data.results.find((r) => r.message === 'Turnstile Cloudflare detected.')) {
      return response.data;
    } else {
      throw new Error(response.data.results.find((r) => !r.success)?.message);
    }
  }

  return response.data;
};

export const sendContact = async (data: SendContactParams) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/contacts`, {
    ...data,
    'g-recaptcha-response': data.recaptchaValue
  });

  if (response.status !== 200) {
    throw new Error('Error sending contact form');
  }

  return response.data;
};
