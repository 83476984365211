import React from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { Report } from '../../lib/api/requests';

export type ReportReact = {
  url: string;
  results: ReportResult[];
};

export type ReportResult = {
  success: boolean;
  message?: string;
  data: Report[];
  url: string;
  issues: number;
};

interface IReportTableProps {
  fullReportUrl: string;
  report: ReportResult;
}

const ReportTable: React.FC<IReportTableProps> = ({ report, fullReportUrl }) => {
  const getTableRowColor = (impact: string) => {
    switch (impact) {
      case '1 - critical':
        // return 'rgba(255, 0, 0, 0.5)';
        return '#FF00007F';
      case '2 - serious':
        // return 'rgba(255, 153, 0, 0.5)';
        return '#FF99007F';
      case '3 - moderate':
        // return 'rgba(255, 255, 0, 0.5)';
        return '#FFFF007F';
      case '4 - minor':
        // return 'rgba(0, 200, 0, 0.5)';
        return '#00C8007F';
      default:
        return 'transparent';
    }
  };

  return (
    <Box py={3}>
      <Grid item xs={12}>
        <Typography variant={'body1'}>
          <strong>Page:</strong>{' '}
          <a href={report.url} className={'link white'}>
            {report.url}
          </a>
        </Typography>
      </Grid>
      {report.success ? (
        <>
          <Grid item xs={12}>
            <Typography variant={'body1'}>
              <strong>Found violations:</strong> {report.issues}
            </Typography>
          </Grid>
          <Grid item xs={12} py={2}>
            <TableContainer>
              <Table
                sx={{ minWidth: 650, border: '0.0625em solid rgba(255, 255, 255, 0.24)' }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Impact</TableCell>
                    <TableCell>Impact level</TableCell>
                    <TableCell>Count</TableCell>
                    <TableCell>Description</TableCell>
                    {/*<TableCell>Type</TableCell>*/}
                    {/*<TableCell>WCAG</TableCell>*/}
                    {/*<TableCell>Further description</TableCell>*/}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {report.data.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 }
                      }}
                      // style={{ backgroundColor: getTableRowColor(row.impact) }}
                    >
                      <TableCell>{row.impact}</TableCell>
                      <TableCell
                      // style={{ backgroundColor: getTableRowColor(row.impact) }}
                      // className={'impact-circle'}
                      >
                        {/*<span*/}
                        {/*  className={'impact-circle'}*/}
                        {/*  style={{ backgroundColor: getTableRowColor(row.impact) }}*/}
                        {/*>*/}
                        {/*  {row.impact.split(' - ')[0]}*/}
                        {/*</span>*/}
                        <CircleIcon style={{ color: getTableRowColor(row.impact) }} fontSize={'large'} />
                      </TableCell>
                      <TableCell>{row.nodes}</TableCell>
                      <TableCell>{row.help}</TableCell>
                      {/*<TableCell>Automatic</TableCell>*/}
                      {/*<TableCell>{row.wcag}</TableCell>*/}
                      {/*<TableCell>{row.description}</TableCell>*/}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} pt={5}>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={() => window.open(fullReportUrl, '_blank')}
              aria-label={'Open full report'}
            >
              Show more
            </Button>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography variant={'body1'}>
              <strong>Scan Error:</strong> Unable to Complete WCAG Analysis
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'body1'}>
              <strong>Reason:</strong> The webpage you are attempting to scan is protected by Cloudflare, and our tool
              is unable to access it for analysis. Cloudflare security settings are preventing automated scanning tools
              from accessing this page. This can occur when the page is protected against bots or unusual traffic.
            </Typography>
          </Grid>
          <Grid item xs={12} pt={2}>
            <Typography variant={'body1'}>
              <strong>Solution:</strong> Our team can perform a manual review of the webpage to ensure it meets WCAG
              standards.
            </Typography>
            <Typography variant={'body1'}>
              Please reach out to us at{' '}
              <a href={'mailto:support@mnb.solutions?subject=Manual page review'} className={'email-link white'}>
                support@mnb.solutions
              </a>{' '}
              to get started with the manual scan.
            </Typography>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default ReportTable;
