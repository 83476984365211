import React from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import DoneIcon from '@mui/icons-material/Done';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import { Box, styled } from '@mui/material';
import ReactGA from 'react-ga4';

import { contactRoute, reportRoute } from '../Browser/routes';
import { useNavigate } from 'react-router-dom';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#16143B'
  },
  '&:nth-of-type(odd)': {
    backgroundColor: '#292C67'
  },
  '&:last-child td, &:last-child th': { border: 0 },
  'td, th': { border: '1px solid rgba(255, 255, 255, 0.24)' }
}));

const StyledIconCell = styled(TableCell)(({ theme }) => ({
  verticalAlign: 'baseline',
  textAlign: 'center'
}));

interface PricingTableProps {}

const PricingTable: React.ComponentType<PricingTableProps> = ({}) => {
  const navigate = useNavigate();

  const getInTouch = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Get In Touch'
    });
    navigate(contactRoute());
  };

  const getItNow = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Get It Now'
    });
    navigate(reportRoute());
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650, border: '0.0625em solid rgba(255, 255, 255, 0.24)' }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: '#16143B'
            }}
          >
            <TableCell style={{ width: '15%', fontWeight: 'bold' }}>Solution</TableCell>
            <TableCell style={{ width: '25%', fontWeight: 'bold' }}>Description</TableCell>
            <StyledIconCell style={{ width: '15%', fontWeight: 'bold' }}>AccessiBridge Essentials</StyledIconCell>
            <StyledIconCell style={{ width: '15%', fontWeight: 'bold' }}>AccessiBridge Pro</StyledIconCell>
            <StyledIconCell style={{ width: '15%', fontWeight: 'bold' }}>AccessiBridge Ultimate</StyledIconCell>
            <StyledIconCell style={{ width: '15%', fontWeight: 'bold' }}>AccessiBridge Continuity</StyledIconCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <TableCell>
              AccessiBridge <br /> Overview Report
            </TableCell>
            <TableCell>
              Our automated report identifies and categorizes up to 50% of accessibility issues. This clear and
              easy-to-read report is perfect for engaging stakeholders, helping them clearly understand areas for
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              improvement and take the necessary action.{' '}
              <a
                href={'https://reports.accessibridge.com/sample/audit_overview_contact_form_high_level_only.html'}
                target={'_blank'}
                rel="noreferrer"
              >
                Download a sample report
              </a>{' '}
              to see how it can help you.
            </TableCell>
            <StyledIconCell>
              <DoneIcon />
            </StyledIconCell>
            <StyledIconCell>
              <DoneIcon />
            </StyledIconCell>
            <StyledIconCell>
              <DoneIcon />
            </StyledIconCell>
            <StyledIconCell>
              <DoneIcon />
              <Typography variant={'body2'}>Regularly updated</Typography>
            </StyledIconCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell>
              AccessiBridge <br /> Remediation Blueprint
            </TableCell>
            <TableCell>
              Building on the Overview Report and along with expert consultation, this detailed report provides
              technical insights for developers and content managers, showing exactly what needs fixing to achieve
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              accessibility compliance.{' '}
              <a
                href={'https://reports.accessibridge.com/sample/audit_overview_contact_form_no_guided_tests.html'}
                target={'_blank'}
                rel="noreferrer"
              >
                Download a sample report
              </a>{' '}
              to see how it can help you.
            </TableCell>
            <TableCell />
            <StyledIconCell>
              <DoneIcon />
            </StyledIconCell>
            <StyledIconCell>
              <DoneIcon />
            </StyledIconCell>
            <StyledIconCell>
              <DoneIcon />
              <Typography variant={'body2'}>Regularly updated</Typography>
            </StyledIconCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell>
              AccessiBridge <br /> Expert Audit
            </TableCell>
            <TableCell>
              Gain confidence with our expert-led audit, which ensures that up to 100% of accessibility issues are
              identified and addressed. This comprehensive review guarantees that your site meets the highest
              accessibility standards, delivering a fully compliant and user-friendly experience.{' '}
              <a
                href={'https://reports.accessibridge.com/sample/audit_overview_contact_form_with_guided_tests.html'}
                target={'_blank'}
                rel="noreferrer"
              >
                Download a sample report
              </a>{' '}
              to see how it can help ensure your site meets the highest standards.
            </TableCell>
            <TableCell />
            <StyledIconCell>
              <DoneIcon />
            </StyledIconCell>
            <StyledIconCell>
              <DoneIcon />
            </StyledIconCell>
            <StyledIconCell>On demand</StyledIconCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell>Remediation Implementation & Support</TableCell>
            <TableCell>
              Our team of expert architects, developers and content managers, with years of experience in accessibility,
              can fully take over your remediation project. Whether you need us to manage the entire process or provide
              extra support, we’ll ensure your website meets full compliance seamlessly.
            </TableCell>
            <TableCell />
            <TableCell />
            <StyledIconCell>
              <DoneIcon />
            </StyledIconCell>
            <StyledIconCell>On demand</StyledIconCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell>Continuous Site Monitoring</TableCell>
            <TableCell>
              Stay ahead of accessibility challenges with regular evaluations that identify new websites and any changes
              to existing ones. Regular monitoring ensures that your site remains compliant over time, so you never have
              to worry about falling behind.
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <StyledIconCell>
              <DoneIcon />
            </StyledIconCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell>Automated Functionality Tests</TableCell>
            <TableCell>
              Ensure a seamless user experience with automated tests that quickly identify issues like broken links,
              faulty navigation, and other functionality problems. These tests ensure a seamless, accessible experience
              for all users by catching issues before they impact your audience.
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <StyledIconCell>
              <DoneIcon />
            </StyledIconCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell>User-Friendly Dashboard</TableCell>
            <TableCell>
              Easily track your website’s compliance status in real-time with our intuitive, user-friendly dashboard. It
              gives you a clear view of your progress, helping you stay informed and in control of your site’s ongoing
              accessibility efforts.
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <StyledIconCell>
              <DoneIcon />
            </StyledIconCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledIconCell style={{ textAlign: 'start' }}>Pricing</StyledIconCell>
            <TableCell></TableCell>
            <StyledIconCell>
              <Button variant={'contained'} color={'primary'} onClick={getItNow}>
                get it now
              </Button>
              <Box pt={2}>It's Free</Box>
            </StyledIconCell>
            <StyledIconCell>
              <Button variant={'contained'} color={'primary'} onClick={getInTouch}>
                Get in touch
              </Button>
              <Box pt={2}>
                From 299,00€ / <br />
                Content Page
              </Box>
            </StyledIconCell>
            <StyledIconCell>
              <Button variant={'contained'} color={'primary'} onClick={getInTouch}>
                Get in touch
              </Button>
            </StyledIconCell>
            <StyledIconCell>
              <Button variant={'contained'} color={'primary'} onClick={getInTouch}>
                Get in touch
              </Button>
            </StyledIconCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PricingTable;
