import React, { useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';

import useOnScreen from '../../hooks/useOnScreen';

interface LazyShowProps {
  children: React.ReactNode;
  parentStyles?: React.CSSProperties;
  childStyles?: React.CSSProperties;
}

const LazyShow: React.FC<LazyShowProps> = ({ children, parentStyles, childStyles }) => {
  // const [props, api] = useSpring(
  //   () => ({
  //     from: { opacity: 0, x: 10 },
  //     to: { opacity: 1, x: 0 },
  //     config: { duration: 500 }
  //   }),
  //   []
  // );
  //
  // const rootRef = useRef<HTMLDivElement>(null);
  // const onScreen = useOnScreen({ ref: rootRef });
  //
  // useEffect(() => {
  //   if (onScreen) {
  //     api.start({
  //       from: { opacity: 0 },
  //       to: { opacity: 1 }
  //     });
  //   }
  // }, [onScreen, api]);
  //
  // return (
  //   <animated.div ref={rootRef} style={props}>
  //     {children}
  //   </animated.div>
  // );

  const controls = useAnimation();
  const rootRef = useRef<HTMLDivElement>(null);
  const onScreen = useOnScreen({ ref: rootRef, rootMargin: '-50px' });
  useEffect(() => {
    if (onScreen) {
      // controls.start({
      //   x: 0,
      //   opacity: 1,
      //   // rotate: -10,
      //   // transition: {
      //   //   duration: 0.5,
      //   //   ease: 'easeOut'
      //   // }
      //   transition: {
      //     type: 'spring',
      //     bounce: 0.4,
      //     duration: 0.8,
      //     staggerChildren: 0.2, // Stagger children with a delay of 0.2s
      //     delayChildren: 0.1 // Optional: Delay before starting to animate children
      //   }
      // });
      controls.start('visible');
    }
  }, [onScreen, controls]);

  const containerVariants = {
    hidden: { opacity: 0.5 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.3 // Stagger children with a delay of 0.3s
      }
    }
  };

  const childVariants = {
    hidden: { opacity: 0.5, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 100,
        damping: 20
      }
    }
  };

  return (
    <motion.div
      ref={rootRef}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
      style={{ ...parentStyles }}
    >
      {React.Children.map(children, (child) => (
        <motion.div variants={childVariants} style={{ ...childStyles }}>
          {child}
        </motion.div>
      ))}
    </motion.div>
  );
};

export default LazyShow;
