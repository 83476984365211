import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import { ErrorMessage } from '@hookform/error-message';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ReCAPTCHA from 'react-google-recaptcha';
import Alert from '@mui/material/Alert';
import ReactGA from 'react-ga4';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Helmet } from 'react-helmet';

import useCaptcha from '../../hooks/useCaptcha';
import { getReport } from '../../lib/api/requests';
import ReportTable, { ReportReact } from '../../components/Report/ReportTable';
import { LoadingLoader } from '../../components/Loader/LopadingLoader';

type FormValues = {
  website: {
    url: string | null;
  }[];
  email: string;
  name?: string;
};

const Report = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<ReportReact | null>(null);
  const { recaptchaValue, recaptchaRef, handleRecaptchaChange } = useCaptcha();

  const { register, handleSubmit, control, formState, reset } = useForm<FormValues>({
    defaultValues: {
      website: [{ url: null }]
    },
    mode: 'onBlur'
  });

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'website'
  });

  console.log('errors: ', formState.errors);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      setLoading(true);
      setError(null);
      setData(null);
      const recaptchaValue = recaptchaRef.current?.getValue();
      const { website, email, name } = data;
      const urls: string[] = website.filter((w) => w.url !== null).map((w) => w.url) as string[];

      console.log('recaptchaValue');

      const report = await getReport({ urls, email, name, recaptchaValue: recaptchaValue as string });

      if (report.results && report.results.length > 0) {
        ReactGA.event({
          category: 'Button',
          action: 'Get final report'
        });
      }

      setData({
        url: report.url,
        results: report.results?.map((r) => ({
          success: r.success,
          message: r.message,
          data: r?.data,
          url: r?.url,
          issues: r?.data?.map((d) => d.nodes)?.reduce((a, b) => a + b, 0)
        }))
      });

      console.log(report);
      setTimeout(() => {
        document.getElementById('section-result')?.scrollIntoView({ behavior: 'smooth' });
      }, 10);
    } catch (error: any) {
      console.error('error: ', error);
      if (error.response) {
        setError(error.response.data.message);
      } else {
        setError(error.message);
      }
    } finally {
      setLoading(false);
      recaptchaRef.current?.reset();
      reset();
    }
  };

  return (
    <>
      <Helmet>
        <title>Report | Accessibility Audit Report</title>
        <link rel="canonical" href="https://accessibridge.com/report" />
        <meta name="description" content="Get your free accessibility audit report for your website" />
        <meta name="keywords" content="accessibility, audit, report, free, website" />
        <meta property="og:title" content="Report | Accessibility Audit Report" />
        <meta property="og:description" content="Get your free accessibility audit report for your website" />
        <meta property="og:url" content="https://accessibridge.com/report" />
        <meta property="og:type" content="website" />
      </Helmet>
      <main role="main" style={{ minHeight: '100dvh', height: '100%' }}>
        {/*<main role="main">*/}
        <section id={'section-1'} className={'main-bg'} style={{ minHeight: '100dvh', height: '100%' }}>
          <Paper
            sx={(theme) => ({
              p: 5,
              margin: 'auto',
              maxWidth: 1250,
              // height: '100%',
              minHeight: 'inherit',
              display: 'flex',
              alignItems: 'center',
              // flexGrow: 1,
              backgroundColor: 'transparent',
              ...theme.applyStyles('dark', {
                backgroundColor: '#1A2027'
              })
            })}
            elevation={0}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} pb={2}>
                  <Typography variant={'h5'} component={'h1'}>
                    Get Your Accessibility Audit Report
                  </Typography>
                  <Typography variant={'body2'}>Enter your website URL and email to get your report</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    onClick={() => {
                      append({ url: null });
                    }}
                    disabled={fields.length >= 5}
                    color={'primary'}
                    variant={'contained'}
                    aria-label={'Add another website URL'}
                  >
                    <AddIcon />
                  </Button>
                </Grid>
                {fields.map((field, index) => (
                  <React.Fragment key={field.id}>
                    <Grid item xs={11} key={field.id}>
                      <TextField
                        {...register(`website.${index}.url`, {
                          required: true
                          // pattern: {
                          //   value:
                          //     /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                          //   message: 'Invalid URL'
                          // }
                        })}
                        id={`website.${index}`}
                        type="text"
                        label={`${index + 1}. Website URL`}
                        variant="outlined"
                        error={!!formState.errors.website?.[index]?.url}
                        fullWidth
                        required
                      />
                      {/*<ErrorMessage errors={formState.errors} name={`website.${index}.url`} as={<Alert severity="error" />}>*/}
                      <ErrorMessage
                        errors={formState.errors}
                        name={`website.[${index}].url`}
                        as={<Typography variant={'body2'} color={'error'} />}
                      />
                    </Grid>
                    <Grid item xs={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                      <IconButton
                        onClick={() => remove(index)}
                        disabled={fields.length === 1}
                        color={'primary'}
                        aria-label={'Remove website URL'}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </React.Fragment>
                ))}
                <Grid item xs={12}>
                  <TextField
                    {...register('email', { required: true })}
                    id="email"
                    type="email"
                    name="email"
                    label="E-mail"
                    variant="outlined"
                    error={!!formState.errors.email}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register('name')}
                    id="name"
                    type="text"
                    name="name"
                    label="Your name"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} mt={1}>
                  <Typography variant={'body2'}>
                    By clicking "GET YOUR REPORT NOW", you agree to our Terms of Services and Privacy Policy. By
                    providing your email and name, you will receive a detailed WCAG report for your website. Your
                    personal data will be stored for a maximum of 6 months. You can withdraw your consent at any time by
                    contacting us at{' '}
                    <a href={'mailto:support@mnb.solutions'} className={'email-link'}>
                      support@mnb.solutions
                    </a>
                    .
                  </Typography>
                </Grid>

                <Grid item xs={12} mt={2}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    onChange={handleRecaptchaChange}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                  />
                </Grid>

                <Grid item xs={12} mt={0} justifyContent={'center'} display={'flex'}>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    type={'submit'}
                    disabled={formState.isValid && !recaptchaValue}
                    aria-label={'Get your free report now'}
                  >
                    Get your free report now
                  </Button>
                </Grid>

                {error && (
                  <Grid item xs={12}>
                    <Alert severity="error">{error}</Alert>
                  </Grid>
                )}
              </Grid>
            </form>
          </Paper>
        </section>

        <section id={'section-result'} className={'main-bg'} style={{ height: 'auto' }}>
          {data && (
            <>
              <Box py={7} />
              <Paper
                sx={(theme) => ({
                  p: 5,
                  margin: 'auto',
                  maxWidth: 1250,
                  // flexGrow: 1,
                  backgroundColor: 'transparent',
                  ...theme.applyStyles('dark', {
                    backgroundColor: '#1A2027'
                  })
                })}
                elevation={0}
              >
                <Grid container justifyContent={'space-between'} columnGap={1}>
                  <Grid item xs={12}>
                    <Typography variant={'h2'} fontWeight={'normal'} pb={2}>
                      AccessiBridge Report Overview
                    </Typography>
                  </Grid>

                  {data.results.map((result, index) => (
                    <ReportTable key={index} report={result} fullReportUrl={data.url} />
                  ))}
                </Grid>
              </Paper>
              <Box py={7} />
            </>
          )}
        </section>
        {loading && <LoadingLoader />}
      </main>
    </>
  );
};

export default Report;
