import React from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

import { contactRoute } from '../Browser/routes';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#16143B'
  },
  '&:nth-of-type(odd)': {
    backgroundColor: '#292C67'
  },
  'td, th': { border: '1px solid rgba(255, 255, 255, 0.24)' }
}));

const AddonsTable: React.ComponentType = () => {
  const navigate = useNavigate();

  const getInTouch = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Get In Touch'
    });
    navigate(contactRoute());
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650, border: '0.0625em solid rgba(255, 255, 255, 0.24)' }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: '#16143B'
            }}
          >
            <TableCell style={{ width: '15%', fontWeight: 'bold' }}>Feature</TableCell>
            <TableCell style={{ width: '25%', fontWeight: 'bold' }}>Description</TableCell>
            <TableCell style={{ width: '15%', fontWeight: 'bold' }}>Available in</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <TableCell>Remediation Implementation & Support</TableCell>
            <TableCell>
              Our team of expert architects, developers and content managers, with years of experience in accessibility,
              can fully take over your remediation project. Whether you need us to manage the entire process or provide
              extra support, we’ll ensure your website meets full compliance seamlessly.
            </TableCell>
            <TableCell>
              <Button variant={'contained'} color={'primary'} onClick={getInTouch}>
                Get in touch
              </Button>
              <Box pt={2}>Available for all teams – On demand</Box>
            </TableCell>
          </StyledTableRow>

          <StyledTableRow>
            <TableCell>Customization</TableCell>
            <TableCell>
              Tailor any report or service to meet your specific needs. Contact us for a personalized solution. Contact
              us for a personalized quote.
            </TableCell>
            <TableCell>
              <Button variant={'contained'} color={'primary'} onClick={getInTouch}>
                Get in touch
              </Button>
              <Box pt={2}>Available for all teams – On demand</Box>
            </TableCell>
          </StyledTableRow>

          <StyledTableRow>
            <TableCell>Accessibility Consultation</TableCell>
            <TableCell>
              On-demand one on one consultation with our accessibility experts to provide tailored advice for your
              website’s compliance.
            </TableCell>
            <TableCell>
              <Button variant={'contained'} color={'primary'} onClick={getInTouch}>
                Get in touch
              </Button>
              <Box pt={2}>Get tailored advice from our accessibility experts</Box>
            </TableCell>
          </StyledTableRow>

          <StyledTableRow>
            <TableCell>Accessibility Training for Developers & Content Managers</TableCell>
            <TableCell>
              Expert-led training for developers and content managers, equipping them with the skills to build fully
              accessible websites. Ensure your team is confident and fully equipped to build websites that comply with
              the latest standards.
            </TableCell>
            <TableCell>
              <Button variant={'contained'} color={'primary'} onClick={getInTouch}>
                Get in touch
              </Button>
              <Box pt={2}>Available for all teams – On demand</Box>
            </TableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AddonsTable;
