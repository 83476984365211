import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material';

import './PrivacyPolicy.scss';

const PrivacyPolicy = () => {
  const theme = useTheme();
  useEffect(() => {
    const body = document.querySelector('body');
    const script = document.createElement('script');

    script.src = 'https://consent.cookiebot.com/b68078bb-5a6f-45bb-8b03-8f0dfce56390/cd.js';
    script.async = true;
    script.type = 'text/javascript';
    // script.setAttribute('data-cbid', 'b68078bb-5a6f-45bb-8b03-8f0dfce56390');
    // script.setAttribute('data-blockingmode', 'auto');
    script.setAttribute('id', 'CookieDeclaration');

    body?.appendChild(script);

    return () => {
      body?.removeChild(script);
    };
  }, []);

  return (
    <main role="main" className={'main-bg'}>
      <Paper
        sx={() => ({
          p: 5,
          margin: 'auto',
          maxWidth: 1250,
          // flexGrow: 1,
          backgroundColor: 'transparent',
          ...theme.applyStyles('dark', {
            backgroundColor: '#1A2027'
          })
        })}
        elevation={0}
      >
        <div id="CookieDeclaration" className="CookieDeclaration" lang="en" dir="ltr"></div>
        <div id="CookieDeclarationUserStatusPanel" className="CookieDeclarationUserStatusPanel"></div>
      </Paper>
    </main>
  );
};

export default PrivacyPolicy;
